import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { Container } from "react-bootstrap"
import Introduction from "../../../components/pageIntroduction"
import RelatedResources from "../../../components/relatedResources"
import Cta from "../../../components/cta"
import Favicon from "../../../images/favicon.png"

const ThankYouPage = ({ data, ...props }) => {
  const thankYou = data.strapiDownloads
  const allDownloads = data.allStrapiDownloads.nodes
  const relatedDownloads = allDownloads
    .filter(download => {
      return download.strapiId !== thankYou.strapiId
    })
    .slice(0, 3)

  return (
    <Layout>
      <Container className="page resources">
        <Seo
          title={
            thankYou.seo !== null
              ? thankYou.seo.meta_title !== null
                ? thankYou.seo.meta_title
                : "Geen meta titel"
              : ""
          }
          image={{
            src:
              thankYou.seo !== null
                ? thankYou.seo.meta_image !== null
                  ? thankYou.seo.meta_image.image.localFile.publicURL
                  : ""
                : "",
            height: 628,
            width: 1200,
          }}
          description={
            thankYou.seo !== null
              ? thankYou.seo.meta_description !== null
                ? thankYou.seo.meta_description
                : ""
              : ""
          }
          pathname={props.location.pathname}
          favicon={Favicon}
        />
        <Introduction
          title={
            thankYou.thank_you_page !== null
              ? thankYou.thank_you_page.Title
              : `Awesome! Dankje voor het downloaden van onze ${thankYou.downloads_categories[0].name}`
          }
          subtitle={
            thankYou.thank_you_page !== null
              ? thankYou.thank_you_page.Subtitle
              : "Wij zullen jij het document binnenkort per email versturen."
          }
        />
        <RelatedResources
          title="Andere downloads"
          data={relatedDownloads}
          name="Downloads"
          padding={{ bottom: 0, top: 80, left: 15, right: 15 }}
        />
        <Cta
          verticalStyling={false}
          title=""
          intro="Doelstellingen vertalen naar design, development en marketing."
          link="/discovery"
          linkText="Ontdek de mogelijkheden"
          titleSize="h2"
          className="resources-cta"
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ThankYouDownloadQuery($slug: String!) {
    strapiDownloads(slug: { eq: $slug }) {
      strapiId
      title
      slug
      thank_you_page {
        Title
        Subtitle
      }
      downloads_categories {
        id
        name
        published_at(formatString: "DD MMMM YYYY", locale: "nl")
        slug
      }
      seo {
        id
        indexable
        meta_description
        meta_keywords
        meta_title
        meta_image {
          meta_image_alt
          image {
            localFile {
              absolutePath
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allStrapiDownloads(sort: { fields: published_at, order: DESC }) {
      nodes {
        published_at(formatString: "DD MMMM YYYY", locale: "nl")
        slug
        id
        strapiId
        title
        updated_at(formatString: "DD MMMM YYYY", locale: "nl")
        labels {
          color
          id
          name
          slug
        }
        created_at(formatString: "DD MMMM YYYY", locale: "nl")
        featured_image {
          alternativeText
          url
          localFile {
            publicURL
            absolutePath
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default ThankYouPage
